.mainWrapper {
    background-color: #Fcf8f2;
}

.container {
    max-width: 1260px;
    padding: 60px 34px 0 34px;
    width: 100%;
    margin: 0 auto;

    @media(max-width:768px) {
        padding: 50px 24px 0 24px;
    }
}

.searchfilter {
    display: flex;
    gap: 20px;

    @media(max-width:1200px) {
        flex-wrap: wrap;
    }

    @media(max-width:768px) {
        flex-direction: column;
    }
}

.searchBox {
    &>div {
        position: relative;

        & form {
            & input {
                background: #fff;
                min-width: 380px;
                min-height: 40px;
                padding-left: 60px;
                padding-right: 60px;
                border: 1px solid #E6E6E6;
                font-family: 'Karla', sans-serif;
                font-size: 16px;
                font-style: italic;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.34823527932167053px;
                text-align: left;
                color: #5b4c23;

                &::placeholder {
                    color: #5b4c23;
                }

                @media(max-width:1500px) {
                    min-width: 490px;
                }

                @media(max-width:1200px) {
                    min-width: 450px;
                }

                @media(max-width:768px) {
                    min-width: 100%;
                    padding-right: 31px;
                }

            }

            &>button {
                border: none;
                cursor: pointer;
            }

            & :global(.ais-SearchBox-submit) {
                position: absolute;
                top: 5px;
                left: 14px;

                & svg {
                    width: 20px;
                    height: 20px;

                    & path {
                        fill: #A58930;
                    }
                }
            }

            & :global(.ais-SearchBox-reset) {
                position: absolute;
                top: 9px;
                right: 6px;

                & svg {
                    width: 18px;
                    height: 18px;

                    & path {
                        fill: #5B4C23;
                    }
                }
            }
        }
    }
}

.rightFilters {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;

    @media(max-width:768px) {
        display: block;
    }
}

.filterDesktop {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;

    @media(max-width:768px) {
        flex-direction: column;
        gap: 16px;
    }

    &>div {
        &:nth-child(2) {
            & :global(.ais-Panel-body) {
                & select {
                    border: 1px solid #5B4C23;
                    font-family: 'Karla', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    color: #5B4C23;
                    line-height: 16px;
                    letter-spacing: -0.20000000298023224px;
                    text-align: center;
                    background: #F2E9E0;
                    min-height: 40px;
                    width: 70px;
                    padding: 0 12px;
                    border-radius: 4px;
                    position: relative;
                    cursor: pointer;
                }
            }
        }
    }

    & :global(.ais-Panel-body) {
        & select {
            border: 1px solid #5B4C23;
            font-family: 'Karla', sans-serif;
            font-size: 13px;
            font-weight: 500;
            color: #5B4C23;
            line-height: 16px;
            letter-spacing: -0.20000000298023224px;
            text-align: center;
            background: #F2E9E0;
            min-height: 40px;
            width: 120px;
            padding: 0 12px;
            border-radius: 4px;
            position: relative;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.priceInput {
    &>div {
        @media (max-width:768px) {
            display: block;
        }

        & button {
            border: 1px solid #5B4C23;
            font-family: 'Karla', sans-serif;
            font-size: 13px;
            font-weight: 500;
            color: #5B4C23;
            line-height: 16px;
            letter-spacing: -0.20000000298023224px;
            text-align: center;
            background: #F2E9E0;
            min-height: 40px;
            width: fit-content;
            padding: 0 16px;
            border-radius: 4px;
            position: relative;
            cursor: pointer;

            @media (max-width:768px) {
                width: 100%;
            }
        }

        & :global(.ais-RangeInput) {
            position: absolute;
            background-color: #fff;
            padding: 14px;
            box-shadow: 0 8px 16px 0 #0000004d;
            z-index: 10;

            & form {
                display: flex;
                align-items: center;
                gap: 4px;

                & button {
                    min-height: 40px;
                    font-weight: 600;
                    background-color: #5B4C23;
                    cursor: pointer;
                    color: #ffffff;
                }
            }
        }
    }

    & .priceDropDown {
        position: absolute;
        background-color: #ffffff;
        padding: 15px 30px 17px;
        background-color: var(--colorWhite);
        box-shadow: var(--boxShadowPopup);
        display: block;
        margin-top: 7px;
        max-height: 500px;
        min-width: 300px;
        overflow-y: auto;
        position: absolute;
        z-index: var(--zIndexPopup);
        border-radius: var(--borderRadiusMedium);
        border-top: 1px solid var(--colorGrey100);
        /* transition: var(--transitionStyleButton); */
@media(max-width:768px) {
    min-width: 90%;
}
        & li {

            & input {
                display: none;
            }

            & input[type='checkbox']+:before {
                content: "";
                background-position: center;
                border: 0.1em solid #ccc3c3;
                border-radius: 5px;
                display: inline-block;
                width: 26px;
                min-width: 26px;
                height: 26px;
                vertical-align: bottom;
                color: transparent;
                transition: 0.2s;
                margin-right: 14px;

                @media (max-width: 767px) {
                    min-width: 26px;
                }
            }

            & input[type='checkbox']+:active:before {
                transform: scale(0);
            }

            & input[type='checkbox']:checked+:before {
                background: var(--successColor);
                border-color: var(--matterColorNegative);
                color: white;
                background-image: url(../../assets/images/Checkmark.png);
                background-repeat: no-repeat;
                background-size: 26px;
                background-position: center;
            }

            & input[type='checkbox']:disabled+:before {
                transform: scale(1);
                border-color: var(--matterColorNegative);
            }

            & input[type='checkbox']:checked:disabled+:before {
                transform: scale(1);
                background-color: #f7c28f;
                border-color: #f7c28f;
            }

            & .selectCategory {
                font-family: 'karla', sans-serif;
                font-size: 14px;
                color: var(--colorBlack);
                display: flex;
                align-items: center;

                & .count {
                    margin-left: 4px;
                }
            }
        }
    }
}

.shortWrapper {
    & select {
        border: none;
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3047058880329132px;
        color: #4A4A4A;
        text-align: center;
        background: transparent;
        min-height: 40px;
        width: fit-content;
        padding: 0 18px;
        border-radius: 4px;
        position: relative;
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12.101%204.40002V14.3%22%20stroke%3D%22%234A4A4A%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3Cpath%20d%3D%22M15.1436%2010.7L12.101%2014.3L9.05835%2010.7%22%20stroke%3D%22%234A4A4A%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3Cpath%20d%3D%22M4.49441%2011.6V1.70001%22%20stroke%3D%22%234A4A4A%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3Cpath%20d%3D%22M1.45178%205.30001L4.49443%201.70001L7.53707%205.30001%22%20stroke%3D%22%234A4A4A%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
        background-position: left;
        cursor: pointer;
    }
}

.clearFilter {
    & button {
        border: 1px solid #5B4C23;
        font-family: 'Karla', sans-serif;
        font-size: 13px;
        font-weight: 500;
        color: #5B4C23;
        line-height: 16px;
        letter-spacing: -0.20000000298023224px;
        text-align: center;
        background: #F2E9E0;
        min-height: 40px;
        width: fit-content;
        padding: 0 12px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        @media(max-width:768px) {
            margin-top: 16px;
            width: 100%;
        }
    }
}

.conatinerResult {
    margin-top: 25px;

    & .filterResult {
        & span {
            font-family: 'Josefin Sans';
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.20000000298023224px;
            text-align: left;
            color: #5B4C23;
        }
    }
}

.grid {
    & :global(.ais-Hits) {
        /* width: 100%; */
        /* max-width: 1440px; */

        & :global(.ais-Hits-list) {
            display: flex;
            width: 100%;
            gap: 27px;
            flex-wrap: wrap;
            padding: 0;
            /* grid-template-columns: repeat(4, 1fr); */
            max-width: 100%;
            width: 100%;


            /* @media (max-width: 1200px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (max-width: 900px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 580px) {
                grid-template-columns: repeat(1, 1fr);
            } */
        }

        & :global(.ais-Hits-item) {
            list-style-type: none;
            width: calc(23% - 12px);

            @media(max-width:1360px) {
                width: calc(32% - 12px);
            }

            @media(max-width:1024px) {
                width: calc(49% - 12px);
            }

            &>div {
                & a {
                    &:hover {
                        text-decoration: none;
                    }

                    &>div {
                        & img {
                            /* width: 100%;
                        height: 100%; */
                        }
                    }
                }
            }
        }
    }
}

.mobileFilters {
    display: flex;
    gap: 10px;

    & .mobileButton {
        border: 1px solid #5B4C23;
        font-family: 'Karla', sans-serif;
        font-size: 13px;
        font-weight: 600;
        color: #5B4C23;
        line-height: 16px;
        letter-spacing: -0.20000000298023224px;
        text-align: center;
        background: #F2E9E0;
        min-height: 40px;
        width: fit-content;
        padding: 0 20px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        & select {
            color: #5B4C23;
            padding-right: 0;
            padding-left: 20px;
            font-size: 13px;
            font-weight: 600;
        }
    }
}

.filterModal {
    &>div>div>div {
        margin-top: 50px;
    }
}

.paginationLink {
    margin-top: 100px;

    &>div {
        &>ul {
            align-items: center;
            display: flex;
            gap: 18px;
            justify-content: center;
            margin-top: 45px !important;
            margin-bottom: 0 !important;
            padding: 0;
            position: relative;
            background: #fff;

            & li {

                &:global(.ais-Pagination-item--firstPage),
                &:global(.ais-Pagination-item--lastPage) {
                    display: none;
                }

                &:global(.ais-Pagination-item--previousPage) {
                    left: 14px;
                    position: absolute;
                    cursor: pointer;

                    &>a {
                        color: #A58930;
                        font-size: 28px;
                    }

                    &>span {
                        color: #A58930;
                        font-size: 28px;
                        padding: 14px 0;
                    }
                }

                &:global(.ais-Pagination-item--nextPage) {
                    right: 14px;
                    position: absolute;
                    cursor: pointer;

                    &>a {
                        color: #A58930;
                        font-size: 28px;
                    }

                    &>span {
                        color: #A58930;
                        font-size: 28px;
                    }
                }

                &:global(.ais-Pagination-item--page) {
                    border-bottom: 3px solid #ffffff;
                    padding: 14px 0;
                    width: 30px;
                    cursor: pointer;
                    text-align: center;

                    &>a {
                        color: #4A4A4A;
                        font-family: 'Inter';
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.34823527932167053px;
                        text-align: left;
                        text-decoration: none;
                        /* width: 30px; */
                    }
                }

                &:global(.ais-Pagination-item--selected) {
                    border-bottom: 3px solid #1D1D1F;
                    width: 30px;
                    cursor: pointer;
                    text-align: center;
                    padding: 14px 0;

                    &>a {
                        font-family: 'Karla', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.34823527932167053px;
                        text-align: left;
                        text-decoration: none;
                        /* width: 30px; */
                        color: #1D1D1F;
                    }
                }
            }
        }
    }
}

.moreFilterModal {
    & h4 {
        text-align: center;
        font-size: 24px;
        margin: 24px 0;
    }

    & .moreFilterModalContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        width: 100%;
        place-items: center;
    }
}

.filter {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @media(max-width:768px) {
        flex-direction: column;
        align-items: flex-start;
    }

    & .filterBox {
        display: flex;
        gap: 15px;
        align-items: center;
        /* flex-wrap: wrap; */
        overflow-x: auto;
        max-width: 800px;
        padding-bottom: 5px;
        cursor: pointer;
        width: 100%;

        /* width */
        &::-webkit-scrollbar {
            width: 10px;
            height: 6px;
            padding-top: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #5B4C23;
            border-radius: 4px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        @media(max-width:850px) {
            max-width: 600px;
            padding-bottom: 5px;
        }

        @media(max-width:650px) {
            max-width: 400px;
            padding-bottom: 5px;
        }

        @media(max-width:450px) {
            max-width: 300px;
            padding-bottom: 5px;
        }

        @media(max-width:600px) {
            overflow-x: auto;
            width: 100%;
        }

        & .activesubCat {
            font-family: "Karla", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.20000000298023224px;
            text-align: center;
            background-color: #5B4C23;
            border: 1px solid #F2E9E0;
            color: #F2E9E0;
            padding: 16px 20px;
            border-radius: 4px;
            min-width: 170px;
            cursor: pointer;
            min-height: 0;
            max-width: fit-content;

            @media(max-width:700px) {
                padding: 12px 20px;
            }
        }

        & .inActivesubCat {
            font-family: "Karla1", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.20000000298023224px;
            text-align: center;
            background-color: #F2E9E0;
            border: 1px solid #5B4C23;
            color: #5B4C23;
            padding: 15px 20px;
            border-radius: 4px;   
            width: 170px;
            min-width: max-content;
            cursor: pointer;
            min-height: 0;

            @media(max-width:700px) {
                padding: 12px 20px;
            }
        }
    }

    & .seeMoreButton {
        font-family: "Josefin Sans";
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 1px;
        text-align: left;
        text-transform: uppercase;
        position: relative;
        color: #5B4C23;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }

        & span {
            font-weight: 700;
        }

        @media(max-width:1320px) {
            margin-left: auto;
            border-bottom: 1px solid #5b4c23;
            width: fit-content;
        }

        @media(max-width:560px) {
            font-size: 14px;
        }

        &::after {
            background-color: #5b4c23;
            bottom: 0;
            content: "";
            height: 1px;
            position: absolute;
            width: 100%;
            left: 0;

            /* @media(max-width:1420px) {
            width: 300px;
          } */

            @media(max-width:1320px) {
                display: none;
            }
        }
    }
}

.priceForm {
    display: flex;
    align-items: center;
    flex: 2 1;
    gap: 6px;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 #0000004d;
    padding: 14px;
    position: absolute;
    z-index: 10;

    & .filterNumber {
        display: flex;
        align-items: center;
        gap: 4px;
        & label {
            margin: 0;
        }
        & input {
            min-height: 40px;
        }
    }

    & .submitButton {
        background-color: #5b4c23;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
        min-height: 40px;
        max-width: 50px;
    }
}
.invisibleRefineMentList{
    position: absolute;
  left: -9999px;
}